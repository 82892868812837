<template>
    <div>
        <v-data-table :headers="headers" :items="billing">
            <template v-slot:[`item.status`]="{ item }">
                <v-chip v-if="!item.isInvoiced">Current Period</v-chip>
                <v-chip v-else>{{ item.status }}</v-chip>
            </template>
            <template v-slot:[`item.totalExclVAT`]="{ item }">
                <span v-if="item.totalExclVAT">{{ getSymbol(item.currencyCode) }} {{ item.totalExclVAT.toFixed(2) }}</span>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import getSymbolFromCurrency from 'currency-symbol-map'
export default {
    data: () => ({
        billing: [],
        loading: false,
        headers: [
            {
                text: 'Status',
                value: 'status',
                align: 'center',
            },

            {
                text: 'Date',
                value: 'date',
                align: 'center',
            },
            {
                text: 'Organisation',
                value: 'organisation.name',
                align: 'center'
            },
            {
                text: 'Invoice Number',
                value: 'invoiceNumber',
                align: 'center'
            },
            {
                text: 'Total Excl VAT',
                value: 'totalExclVAT',
                align: 'center'
            }
        ],
        item: {},
        loadingSummary: false,
        showSummary: false
    }),
    created() {
        this.getAdminBilling()
    },
    methods: {
        async getAdminBilling() {
            this.loading = true
            this.billing = await this.$API.getBillingOverview()
            this.loading = false
        },
        async getSummaryData(item) {
            this.loadingSummary = true
            this.item.summary = await this.$API.getMonthlyBillingBreakdown(item.year, item.month)
            this.loadingSummary = false
        },
        getSymbol(currencyCode) {
            return getSymbolFromCurrency(currencyCode)
        },
        async viewSummary(item) {
            this.item = item
            this.showSummary = true
            this.getSummaryData(item)
        }
    }
}
</script>